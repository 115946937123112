import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric3 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 150 146" {...props}>
    <path
      clipRule="evenodd"
      d="M114.317 26.8459C121.684 26.8459 127.657 20.8734 127.657 13.506C127.657 6.13851 121.684 0.166016 114.317 0.166016C106.949 0.166016 100.977 6.13851 100.977 13.506C100.977 20.8734 106.949 26.8459 114.317 26.8459Z"
      fill="url(#paint0_linear_1175_15817)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M29.2293 145.824C36.5968 145.824 42.5693 139.852 42.5693 132.484C42.5693 125.117 36.5968 119.145 29.2293 119.145C21.8619 119.145 15.8894 125.117 15.8894 132.484C15.8894 139.852 21.8619 145.824 29.2293 145.824Z"
      fill="url(#paint1_linear_1175_15817)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M23.6232 101.261C46.2132 114.303 75.099 106.563 88.1414 83.9729C101.184 61.3829 93.4439 32.4971 70.8538 19.4547C48.2637 6.41236 19.378 14.1523 6.33559 36.7423C-6.70679 59.3324 1.03314 88.2182 23.6232 101.261Z"
      fill="url(#paint2_linear_1175_15817)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M79.1466 132.268C101.737 145.311 130.622 137.571 143.665 114.981C156.707 92.3907 148.967 63.5049 126.377 50.4625C103.787 37.4202 74.9014 45.1601 61.859 67.7501C48.8167 90.3402 56.5566 119.226 79.1466 132.268Z"
      fill="url(#paint3_linear_1175_15817)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M92.0875 45.3638C91.9449 45.3913 91.8024 45.4195 91.6601 45.4482C78.5247 48.0999 66.6813 55.8484 60.5028 67.8727C54.2318 80.0772 53.9121 93.7165 57.8801 105.958C57.9226 106.089 57.9657 106.22 58.0094 106.351C58.0465 106.343 58.0834 106.334 58.1204 106.326C70.557 103.567 81.9144 95.3033 88.7867 83.4003C95.7471 71.3443 96.1211 57.7142 92.1151 45.4482C92.1059 45.4201 92.0967 45.3919 92.0875 45.3638Z"
      fill="url(#paint4_linear_1175_15817)"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15817"
        x1="85.8797"
        x2="99.2197"
        y1="6.83599"
        y2="37.0301"
      >
        <stop stopColor="#72A7FF" />
        <stop offset="1" stopColor="#B8BCFF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15817"
        x1="1.07289"
        x2="14.1649"
        y1="125.691"
        y2="155.324"
      >
        <stop stopColor="#FA69A8" />
        <stop offset="1" stopColor="#F27471" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15817"
        x1="17.1624"
        x2="-2.78929"
        y1="48.4411"
        y2="101.87"
      >
        <stop stopColor="#B8BCFF" />
        <stop offset="1" stopColor="#32C3FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_1175_15817"
        x1="79.2877"
        x2="74.5752"
        y1="65.7721"
        y2="139.552"
      >
        <stop stopColor="#FFA478" />
        <stop offset="1" stopColor="#FB67AE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_1175_15817"
        x1="77.9857"
        x2="45.133"
        y1="39.4168"
        y2="96.3192"
      >
        <stop stopColor="#6565EE" />
        <stop offset="1" stopColor="#91A9FF" />
      </linearGradient>
    </defs>
  </Icon>
);
