import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric6 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 145 187" {...props}>
    <path
      d="M0.00012207 93.293C0.00012207 144.655 41.6376 186.293 93.0001 186.293V0.292969C41.6376 0.292969 0.00012207 41.9305 0.00012207 93.293Z"
      fill="url(#paint0_linear_1175_15848)"
    />
    <mask fill="white" id="path-2-inside-1_1175_15848">
      <path
        clipRule="evenodd"
        d="M93.0002 145.683C64.4562 145.683 41.3167 122.544 41.3167 93.9999C41.3167 65.4559 64.4562 42.3164 93.0002 42.3164"
        fillRule="evenodd"
      />
    </mask>
    <path
      d="M93.0002 160.683C56.172 160.683 26.3167 130.828 26.3167 93.9999H56.3167C56.3167 114.26 72.7405 130.683 93.0002 130.683V160.683ZM26.3167 93.9999C26.3167 57.1716 56.172 27.3164 93.0002 27.3164V57.3164C72.7405 57.3164 56.3167 73.7402 56.3167 93.9999H26.3167Z"
      fill="url(#paint1_linear_1175_15848)"
      mask="url(#path-2-inside-1_1175_15848)"
    />
    <mask fill="white" id="path-4-inside-2_1175_15848">
      <path
        clipRule="evenodd"
        d="M93.0002 145.683C121.544 145.683 144.684 122.544 144.684 93.9999C144.684 65.4559 121.544 42.3164 93.0002 42.3164"
        fillRule="evenodd"
      />
    </mask>
    <path
      d="M93.0002 160.683C129.829 160.683 159.684 130.828 159.684 93.9999H129.684C129.684 114.26 113.26 130.683 93.0002 130.683V160.683ZM159.684 93.9999C159.684 57.1716 129.829 27.3164 93.0002 27.3164V57.3164C113.26 57.3164 129.684 73.7402 129.684 93.9999H159.684Z"
      fill="url(#paint2_linear_1175_15848)"
      mask="url(#path-4-inside-2_1175_15848)"
    />
    <path
      d="M102.347 93.292C102.347 98.4541 98.162 102.639 92.9999 102.639C87.8379 102.639 83.6532 98.4541 83.6532 93.292C83.6532 88.13 87.8379 83.9453 92.9999 83.9453C98.162 83.9453 102.347 88.13 102.347 93.292Z"
      fill="#F76F8F"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15848"
        x1="46.5001"
        x2="46.5001"
        y1="0.292969"
        y2="186.293"
      >
        <stop stopColor="#68E0D0" />
        <stop offset="1" stopColor="#30C7F9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15848"
        x1="87.0913"
        x2="33.4073"
        y1="140.551"
        y2="134.413"
      >
        <stop stopColor="#F79768" />
        <stop offset="1" stopColor="#FC6EA9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15848"
        x1="98.9092"
        x2="152.593"
        y1="140.551"
        y2="134.413"
      >
        <stop stopColor="#827FFF" />
        <stop offset="1" stopColor="#ADB0FF" />
      </linearGradient>
    </defs>
  </Icon>
);
