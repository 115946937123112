import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Link as ChakraLink,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import _ from 'lodash';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { merge } from 'lodash/fp';

import { getCourseCampusLink, getLinkByPageName } from '../../lib/links';
import IronhackLogo from '../../components/IronhackLogo';
import { AbstractGeometric1 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric1';
import { AbstractGeometric2 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric2';
import { AbstractGeometric3 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric3';
import { AbstractGeometric4 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric4';
import { AbstractGeometric5 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric5';
import { AbstractGeometric6 } from '../../../static/assets/icons/abstract-geometric/AbstractGeometric6';
import useUserLocation from '../../hooks/useUserLocation';
import campuses from '../../campus.json';
import { getGaConnectorValues } from '../../lib/ga-connector';

type ShortCourseEntryPage = {
  pageContext: {
    collectLead: boolean;
    courseCode: string;
    translationFile: string;
  };
};

const courseBadge: Record<string, React.ReactElement> = {
  js: (
    <StaticImage
      alt="Ironhack Javascript Course"
      height={19}
      layout="constrained"
      placeholder="blurred"
      src={`../../../static/assets/course/js-1.png`}
      width={17}
    />
  ),
  ux: <AbstractGeometric3 boxSize={19} />,
};

const courseCodeMap: Record<string, string> = {
  js: 'courseWeb',
  ux: 'courseUxui',
};

const LeadFormFields = (): React.ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <SimpleGrid columns={2} mt={3} spacing={4}>
      <FormControl
        as={GridItem}
        colSpan={[2, null, 1]}
        isInvalid={errors.firstName}
        isRequired
      >
        <FormLabel>
          {t('application-form:applicationForm.fields.firstName')}
        </FormLabel>
        <Input
          placeholder={t(
            'application-form:applicationForm.placeholders.firstName'
          )}
          {...register('firstName', { required: true })}
        />
      </FormControl>
      <FormControl
        as={GridItem}
        colSpan={[2, null, 1]}
        isInvalid={errors.lastName}
        isRequired
      >
        <FormLabel>
          {t('application-form:applicationForm.fields.lastName')}
        </FormLabel>
        <Input
          placeholder={t(
            'application-form:applicationForm.placeholders.lastName'
          )}
          {...register('lastName', { required: true })}
        />
      </FormControl>
      <FormControl as={GridItem} colSpan={[2, null, 1]} isRequired>
        <FormLabel>
          {t('application-form:applicationForm.fields.campus')}
        </FormLabel>
        <Select
          placeholder={t(
            'application-form:applicationForm.placeholders.campus'
          )}
          {...register('campus', { required: true })}
        >
          {campuses.map((c) => (
            <option key={c.code} value={c.code}>
              {c.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl
        as={GridItem}
        colSpan={[2, null, 1]}
        isInvalid={errors.email}
        isRequired
      >
        <FormLabel>
          {t('application-form:applicationForm.fields.email')}
        </FormLabel>
        <Input
          placeholder={t('application-form:applicationForm.placeholders.email')}
          type="email"
          {...register('email', { required: true })}
        />
      </FormControl>
      <Checkbox {...register('emailOptIn')}>
        {t('application-form:applicationForm:footer.newsletter')}
      </Checkbox>
    </SimpleGrid>
  );
};

const ShortCourseEntry = (props: ShortCourseEntryPage): React.ReactElement => {
  const { collectLead, courseCode, translationFile } = props.pageContext;
  const methods = useForm({
    defaultValues: {
      campus: null,
      email: null,
      emailOptIn: null,
      firstName: null,
      lastName: null,
    },
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userLocation } = useUserLocation();
  const campusVisited =
    userLocation.campusVisited || userLocation.defaultCampus;
  const courseInCampusPage = getCourseCampusLink(
    courseCodeMap[courseCode],
    `campus${_.startCase(campusVisited)}`,
    language
  );

  const handleLeadFormSubmit = methods.handleSubmit((data): void => {
    const formPayload = merge(
      {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        sy_campus: data.campus,
        Email_Opt_In: data.emailOptIn ? 1 : 0,
      },
      getGaConnectorValues(document.cookie || '')
    );

    const baseUrl = process.env.IRONHACK_API?.slice(0, -8) as string;

    fetch(`${baseUrl}/formHandlerSubmission/shortCourseUxEntry`, {
      body: JSON.stringify(formPayload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          throw new Error('Could not complete application');
        }

        return navigate(`/en/${courseCode}-shortcourse-step-1`, {
          state: { lead: { email: data.email } },
        });
      })
      .catch((error) => {
        // log the error and move forward
        // eslint-disable-next-line no-console
        console.error('ERR', error);

        return navigate(`/en/${courseCode}-shortcourse-step-1`, {
          state: { lead: { email: data.email } },
        });
      });
  });

  return (
    <Flex direction={['column', null, null, 'row']}>
      <Box
        as="aside"
        bgColor="darkBlue.100"
        minHeight={['auto', null, null, '100vh']}
        order={[1, null, null, 2]}
      >
        <Grid
          alignItems="center"
          gap={[1, null, null, 2]}
          px={[1, null, null, 3]}
          py={1}
        >
          <GridItem
            colEnd={[2, null, null, 3]}
            colStart={1}
            rowEnd={1}
            rowStart={1}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              p={[2, null, null, 4]}
            >
              <AbstractGeometric1 boxSize={['48px', null, '120px', '66px']} />
            </Box>
          </GridItem>
          <GridItem
            colEnd={[3, null, null, 4]}
            colStart={[2, null, null, 1]}
            rowEnd={[1, null, null, 3]}
            rowStart={[1, null, null, 2]}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              p={[2, null, null, 6]}
            >
              <AbstractGeometric3 boxSize={['48px', null, '120px', '112px']} />
            </Box>
          </GridItem>
          <GridItem
            alignSelf="start"
            colEnd={[4, null, null, 6]}
            colStart={[3, null, null, 3]}
            rowEnd={[1, null, null, 5]}
            rowStart={[1, null, null, 3]}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              overflow="hidden"
              p={[2, null, null, 6]}
              pr={[2, null, null, 0]}
            >
              <AbstractGeometric6 boxSize={['48px', null, '120px', '180px']} />
            </Box>
          </GridItem>
          <GridItem
            alignSelf="center"
            colEnd={[5, null, null, 6]}
            colStart={[4, null, null, 4]}
            rowEnd={[1, null, null, 3]}
            rowStart={[1, null, null, 2]}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              p={2}
            >
              <AbstractGeometric4 boxSize={['48px', null, '120px', '80px']} />
            </Box>
          </GridItem>
          <GridItem
            alignSelf="end"
            colEnd={[2, null, null, 6]}
            colStart={[1, null, null, 3]}
            display={['none', null, null, 'block']}
            rowEnd={[1, null, null, 2]}
            rowStart={1}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              p={[2, null, null, 6]}
            >
              <AbstractGeometric2 boxSize={['48px', null, '120px', '100px']} />
            </Box>
          </GridItem>
          <GridItem
            colSpan={2}
            display={['none', null, null, 'block']}
            rowEnd={[1, null, null, 3]}
            rowStart={[1, null, null, 3]}
          >
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              p={[2, null, null, 4]}
            >
              <AbstractGeometric5 boxSize={['48px', null, '120px', '80px']} />
            </Box>
            <Box
              align="center"
              bgColor="rgba(101, 101, 238, 0.06);"
              borderRadius="15px"
              mt={2}
              p={2}
            >
              <AbstractGeometric4 boxSize={['48px', null, '120px', '80px']} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <Box
        as="main"
        order={[2, null, null, 1]}
        px={[3, null, null, 10]}
        py={[3, null, null, 15]}
      >
        <Flex as="nav">
          <ChakraLink as={Link} to={getLinkByPageName('home')}>
            <IronhackLogo colorScheme="electricBlue" size="l" />
          </ChakraLink>
          <Flex
            align="center"
            borderRadius="card"
            boxShadow="4px 3px 8px rgba(0, 0, 0, 0.09)"
            ml={2}
            p={0.5}
          >
            <Center bgColor="darkBlue.100" borderRadius="card" boxSize="32px">
              {courseBadge[courseCode]}
            </Center>
            <Text colorScheme="darkBlue.100" mx={1} textStyle="body1">
              {t(`${translationFile}:entry.badge`)}
            </Text>
          </Flex>
        </Flex>
        <Box as="section" mt={[2, null, null, 11]}>
          <Text textStyle="title1">
            {t(`${translationFile}:entry.descriptions.title`)}
          </Text>
          <Center
            borderRadius="xl"
            boxShadow="lg"
            display={['flex', null, 'none']}
            my={[4]}
          >
            <HStack py={0.5} spacing={1}>
              <WarningTwoIcon boxSize="2rem" color="yellow.100" />
              <Text maxW="48ch" textStyle="body6">
                {t('short-courses:shortCourses.disclaimer.mobile')}
              </Text>
            </HStack>
          </Center>
          <Text
            mt={[null, null, null, 3]}
            textStyle="body3"
            textTransform="uppercase"
          >
            {t(`${translationFile}:entry.descriptions.subtitle`)}
          </Text>
          <Text color="darkBlue.64" mt={2} textStyle="body4">
            {t(`${translationFile}:entry.descriptions.description`)}
          </Text>
          {collectLead ? (
            <FormProvider {...methods}>
              <Box as="form" onSubmit={handleLeadFormSubmit}>
                <LeadFormFields />
                <Stack direction={['column', null, 'row']} mt={6} spacing={2}>
                  <Button as={Link} to={courseInCampusPage} variant="outline">
                    {t(`${translationFile}:entry.ctas.discover`)}
                  </Button>
                  <Button type="submit">
                    {t(`${translationFile}:entry.ctas.start`)}
                  </Button>
                </Stack>
                <Text mt={2} textStyle="body5" w="100%">
                  {t(
                    'application-form:applicationForm.footer.legalAdvice.byApplyingStart'
                  )}{' '}
                  <ChakraLink as={Link} to={getLinkByPageName('privacyPolicy')}>
                    {t(
                      'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkText'
                    )}
                  </ChakraLink>{' '}
                  {t(
                    'application-form:applicationForm.footer.legalAdvice.byApplyingConjuntion'
                  )}{' '}
                  <ChakraLink as={Link} to={getLinkByPageName('termsOfUse')}>
                    {t(
                      'application-form:applicationForm:footer.legalAdvice.termsOfUseLinkText'
                    )}
                  </ChakraLink>
                </Text>
              </Box>
            </FormProvider>
          ) : (
            <Stack
              direction={['column', null, 'row']}
              mt={[4, null, null, 3]}
              spacing={2}
            >
              <Button as={Link} to={courseInCampusPage} variant="outline">
                {t(`${translationFile}:entry.ctas.discover`)}
              </Button>
              <Button
                as={Link}
                state={{ email: props?.location?.state?.email }}
                to={'classroom'}
              >
                {t(`${translationFile}:entry.ctas.start`)}
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export const query = graphql`
  query (
    $locale: String!
    $defaultLanguage: String!
    $translationFile: String!
  ) {
    locales: allLocale(
      filter: {
        lng: { in: [$locale, $defaultLanguage] }
        ns: {
          in: [
            "application-form"
            "forms"
            "seo"
            "short-courses"
            $translationFile
          ]
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default ShortCourseEntry;
