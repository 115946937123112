import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric4 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 85 85" {...props}>
    <path
      clipRule="evenodd"
      d="M84.2074 84.7227L0.207397 84.7227L0.207397 0.722656"
      fill="url(#paint0_linear_1175_15832)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M35.4874 36.0027L0.207397 36.0027L0.207397 0.722656"
      fill="url(#paint1_linear_1175_15832)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M0.207428 0.721954L35.4874 0.721954L35.4874 36.002"
      fill="url(#paint2_linear_1175_15832)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M59.5674 29.8434C65.753 29.8434 70.7674 24.829 70.7674 18.6434C70.7674 12.4578 65.753 7.44336 59.5674 7.44336C53.3818 7.44336 48.3674 12.4578 48.3674 18.6434C48.3674 24.829 53.3818 29.8434 59.5674 29.8434Z"
      fill="url(#paint3_linear_1175_15832)"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15832"
        x1="0.207397"
        x2="0.207397"
        y1="0.722656"
        y2="84.7227"
      >
        <stop stopColor="#68D3FA" />
        <stop offset="1" stopColor="#C6C9FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15832"
        x1="-7.81252"
        x2="0.991069"
        y1="6.6918"
        y2="40.4045"
      >
        <stop stopColor="#FFA479" />
        <stop offset="1" stopColor="#FC6FA9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15832"
        x1="40.9168"
        x2="45.924"
        y1="33.5301"
        y2="-0.675859"
      >
        <stop stopColor="#31B2F7" />
        <stop offset="1" stopColor="#7DDEFF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_1175_15832"
        x1="35.6922"
        x2="46.8922"
        y1="13.0434"
        y2="38.3939"
      >
        <stop stopColor="#6F69FF" />
        <stop offset="1" stopColor="#B8BCFF" />
      </linearGradient>
    </defs>
  </Icon>
);
