import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric2 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 108 110" {...props}>
    <path
      clipRule="evenodd"
      d="M57.1704 103.063C79.7624 103.063 98.0768 84.7483 98.0768 62.1564C98.0768 39.5644 79.7624 21.25 57.1704 21.25C34.5784 21.25 16.264 39.5644 16.264 62.1564C16.264 84.7483 34.5784 103.063 57.1704 103.063Z"
      fill="url(#paint0_linear_1175_15855)"
      fillOpacity="0.8"
      fillRule="evenodd"
    />
    <path
      d="M59.3281 0.0371094L108 48.709H59.3281V0.0371094Z"
      fill="url(#paint1_linear_1175_15855)"
    />
    <path
      clipRule="evenodd"
      d="M10.3498 109.963C16.0658 109.963 20.6996 105.33 20.6996 99.6135C20.6996 93.8974 16.0658 89.2637 10.3498 89.2637C4.63377 89.2637 0 93.8974 0 99.6135C0 105.33 4.63377 109.963 10.3498 109.963Z"
      fill="url(#paint2_linear_1175_15855)"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15855"
        x1="57.2619"
        x2="106.173"
        y1="109.57"
        y2="52.2821"
      >
        <stop stopColor="#B8BCFF" />
        <stop offset="1" stopColor="#28CAF7" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15855"
        x1="73.4343"
        x2="101.527"
        y1="-8.3199"
        y2="73"
      >
        <stop offset="0.272987" stopColor="#FFA478" />
        <stop offset="1" stopColor="#FB67AE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15855"
        x1="-11.7131"
        x2="-1.36326"
        y1="94.4386"
        y2="117.865"
      >
        <stop stopColor="#564CFF" />
        <stop offset="1" stopColor="#B8BCFF" />
      </linearGradient>
    </defs>
  </Icon>
);
