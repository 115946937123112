import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric1 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 72 70" {...props}>
    <path
      clipRule="evenodd"
      d="M38.2242 55.4619C53.2948 55.4619 65.5118 43.2448 65.5118 28.1743C65.5118 13.1038 53.2948 0.886719 38.2242 0.886719C23.1537 0.886719 10.9366 13.1038 10.9366 28.1743C10.9366 43.2448 23.1537 55.4619 38.2242 55.4619Z"
      fill="url(#paint0_linear_1175_15811)"
      fillRule="evenodd"
    />
    <rect
      height="12.5943"
      stroke="url(#paint1_linear_1175_15811)"
      strokeWidth="12.5943"
      width="12.5943"
      x="6.29714"
      y="50.2229"
    />
    <rect
      fill="url(#paint2_linear_1175_15811)"
      height="9.15947"
      rx="4.57974"
      width="33.5847"
      x="38.4154"
      y="59.2793"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15811"
        x1="38.2852"
        x2="70.9127"
        y1="59.8027"
        y2="21.5874"
      >
        <stop stopColor="#B8BCFF" />
        <stop offset="1" stopColor="#28CAF7" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15811"
        x1="13.032"
        x2="-4.7115"
        y1="45.3878"
        y2="60.2426"
      >
        <stop stopColor="#FFA479" />
        <stop offset="1" stopColor="#FC6FA9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15811"
        x1="57.1276"
        x2="49.5122"
        y1="48.202"
        y2="78.9685"
      >
        <stop stopColor="#564CFF" />
        <stop offset="1" stopColor="#2DC5FA" />
      </linearGradient>
    </defs>
  </Icon>
);
