import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AbstractGeometric5 = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 90 92" {...props}>
    <path
      d="M36.181 37.3496H90V91.1686H36.181V37.3496Z"
      fill="url(#paint0_linear_1175_15839)"
    />
    <path
      clipRule="evenodd"
      d="M72.3617 37.0148C72.3617 37.1393 72.3611 37.2637 72.3598 37.3878H36.3052V73.1955L36.1809 73.1957C16.1987 73.1957 0 56.997 0 37.0148C0 17.0327 16.1987 0.833984 36.1809 0.833984C56.163 0.833984 72.3617 17.0327 72.3617 37.0148ZM21.8189 56.3746C24.3167 56.3746 26.3415 54.3498 26.3415 51.852C26.3415 49.3542 24.3167 47.3294 21.8189 47.3294C19.3212 47.3294 17.2963 49.3542 17.2963 51.852C17.2963 54.3498 19.3212 56.3746 21.8189 56.3746Z"
      fill="url(#paint1_linear_1175_15839)"
      fillRule="evenodd"
    />
    <path
      d="M26.3416 81.5578C26.3416 84.0555 24.3168 86.0804 21.819 86.0804C19.3212 86.0804 17.2964 84.0555 17.2964 81.5578C17.2964 79.06 19.3212 77.0352 21.819 77.0352C24.3168 77.0352 26.3416 79.06 26.3416 81.5578Z"
      fill="#F76F8F"
    />
    <path
      d="M85.2513 26.6085C85.2513 29.1063 83.2264 31.1312 80.7287 31.1312C78.2309 31.1312 76.2061 29.1063 76.2061 26.6085C76.2061 24.1108 78.2309 22.0859 80.7287 22.0859C83.2264 22.0859 85.2513 24.1108 85.2513 26.6085Z"
      fill="#F76F8F"
    />
    <path
      d="M85.2513 12.1359C85.2513 14.6337 83.2264 16.6585 80.7287 16.6585C78.2309 16.6585 76.2061 14.6337 76.2061 12.1359C76.2061 9.63812 78.2309 7.61328 80.7287 7.61328C83.2264 7.61328 85.2513 9.63812 85.2513 12.1359Z"
      fill="#F76F8F"
    />
    <path
      d="M36.181 37.3496V73.1959C56.0515 73.1959 72.1808 57.1777 72.3604 37.3496H36.181Z"
      fill="url(#paint2_linear_1175_15839)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1175_15839"
        x1="63.0905"
        x2="63.0905"
        y1="37.3496"
        y2="99.2322"
      >
        <stop stopColor="#AEDEC8" />
        <stop offset="1" stopColor="#2DC5FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1175_15839"
        x1="37.1755"
        x2="37.1755"
        y1="0.833984"
        y2="73.1855"
      >
        <stop stopColor="#B8BCFF" />
        <stop offset="1" stopColor="#28C9F7" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1175_15839"
        x1="54.2707"
        x2="54.2707"
        y1="37.3496"
        y2="73.1959"
      >
        <stop stopColor="#6A6BF0" />
        <stop offset="1" stopColor="#60C1FF" />
      </linearGradient>
    </defs>
  </Icon>
);
